<template>
  <div class="search-page">
    <div class="container">
      <section class="search-title">
        <h1 class="title">HASIL PENCARIAN “{{ $route.query.q }}”</h1>
        <p class="desc">
          {{
            result.items.length > 0
              ? "Hasil Pencarian " +
                result.pagination.total +
                " Artikel Berkaitan."
              : "Kami tidak dapat menemukan berita dan informasi berdasarkan kata kunci pencarian anda."
          }}
        </p>
      </section>
      <section class="search-category" v-show="result.items.length > 0">
        <div
          class="py-2"
          v-swiper:mySwiperCategories="swiperOptions.categories"
        >
          <div class="swiper-wrapper">
            <ShimmerCategory v-if="result.loading" />
            <div
              role="button"
              class="swiper-slide category"
              v-for="channel in result.channels"
              :key="channel.id"
              :class="channel === filter ? 'active' : ''"
              @click="filter = channel"
            >
              {{ channel }}
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </section>
      <section class="search-result"></section>
      <section class="search-result">
        <div v-if="loadingSearch" class="dot-spin-wrapper">
          <div class="dot-spin m-auto"></div>
        </div>
        <div class="not-found" v-if="result.items.length < 1 && !loadingSearch">
          <Icons name="search-not-found" />
        </div>
        <!-- <div class="dropdown filter">
          <div
            class="dropdown-toggle"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <p class="text">
              {{ sort === "DESC" ? "Paling Baru" : "Paling Lama" }}
            </p>
            <div class="arrow">
              <Icons name="arrow-bottom" />
            </div>
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div class="dropdown-item" @click="sorting('DESC')">
                Paling Baru
              </div>
            </li>
            <li>
              <div class="dropdown-item" @click="sorting('ASC')">
                Paling Lama
              </div>
            </li>
          </ul>
        </div> -->
        <div
          v-if="result.items.length > 0 && !loadingSearch"
          class="search-list-card"
          :class="{ removeRow: result.items.length <= 4 }"
        >
          <ShimmerCardHomeTwo
            v-show="result.loading"
            v-for="(item, index) in 4"
            :key="index + 'x'"
          />
          <CardTen
            v-show="!result.loading"
            v-for="item in this.filter === 'Semua'
              ? result.items
              : result.items.filter(
                  (article) => article.channel.title === this.filter
                )"
            :key="item.id"
            :to="
              item.isText
                ? {
                    name: 'read-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                  }
            "
            :image="
              item.thumbnail && item.thumbnail.medium
                ? item.thumbnail.medium
                : 'https://narasi.tv/storage/images/dummy.png'
            "
            :imageFromUrl="true"
            :duration="item.timeVideo ? item.timeVideo : '00:00'"
            :channel="item.channel ? item.channel.title : ''"
            :channelSlug="item.channel.slug"
            :programSlug="item.channel.program ? item.channel.program.slug : ''"
            :category="item.category ? item.category.title : ''"
            :categorySlug="item.category ? item.category.slug : ''"
            :title="item.title"
            :isText="item.isText"
            :isAdvertorial="item.isAdvertorial"
            class="search-card"
          />
        </div>
        <div
          class="pagination-cards"
          v-if="
            result.items.length > 0 &&
            result.pagination.pages > 1 &&
            !loadingSearch
          "
        >
          <paginate
            v-if="
              filter === 'Semua' &&
              result.pagination &&
              result.pagination.pages &&
              result.pagination.pages > 1
            "
            :page-count="result.pagination.pages"
            :page-range="3"
            :margin-pages="1"
            prev-class="arrow"
            next-class="arrow"
            :click-handler="clickCallback"
            :container-class="'pagination'"
            :page-class="'page-item'"
            v-model="page"
          >
            <template slot="prevContent">
              <Icons name="arrow-left" color="#757575" />
            </template>
            <template slot="nextContent">
              <Icons name="arrow-right" color="#757575" />
            </template>
          </paginate>
        </div>
      </section>
      <section
        class="search-topic-reco"
        v-if="
          $store.state.search.result.topics.length > 0 ||
          $store.state.search.topics.items.length > 0
        "
      >
        <h2 class="topic-title">REKOMENDASI TOPIK</h2>
        <div class="topic-list">
          <ShimmerCategory
            v-if="
              $store.state.search.topics.loading &&
              $store.state.search.result.loading
            "
          />
          <Link
            v-for="topic in $store.state.search.topics.items"
            :key="topic.id"
            :to="`/search?q=${topic.topic}`"
            class="topic"
            :class="$route.query.q === topic.topic ? 'Link-exact-active' : ''"
          >
            <Icons name="ads" v-if="topic.isSponsor" />
            <p>#{{ topic.topic }}</p>
          </Link>
          <!-- <Link
            class="topic"
            v-for="(topic, index) in $store.state.search.result.topics"
            :key="'topic-' + index"
            :to="`/search?q=${topic.topic}`"
            >{{ topic.topic }}
          </Link> -->
        </div>
      </section>
      <section class="search-populer">
        <h2 class="populer-title">PENCARIAN POPULER</h2>
        <div class="populer-card-list">
          <ShimmerCardHomeLandscape
            v-show="$store.state.search.popular.loading"
            v-for="(item, index) in 5"
            :key="'shimmer-x-' + index"
          />
          <CardFive
            v-show="!$store.state.search.popular.loading"
            v-for="item in $store.state.search.popular.items"
            :key="item.id"
            :to="
              item.isText
                ? {
                    name: 'read-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                  }
            "
            :image="
              item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png'
            "
            :imageFromUrl="true"
            :duration="item.timeVideo ? item.timeVideo : '00:00'"
            :channel="item.channel ? item.channel.title : ''"
            :channelSlug="item.channel.slug"
            :programSlug="item.channel.program ? item.channel.program.slug : ''"
            :category="item.category ? item.category.title : ''"
            :categorySlug="item.category ? item.category.slug : ''"
            :title="item.title"
            :isAdvertorial="item.isAdvertorial"
            :isText="item.isText"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import Vue from "vue";
import CardFive from "/components/cards/CardFive.vue";
import CardTen from "/components/cards/CardTen.vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import ShimmerCategory from "@/components/shimmer/Category";
import ShimmerCardHomeTwo from "@/components/shimmer/CardHomeTwo";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";
import Icons from "@/components/Icons";
import Link from "@/components/Link";

Vue.component("paginate", Paginate);
export default {
  components: {
    ShimmerCardHomeLandscape,
    Link,
    ShimmerCardHomeTwo,
    Icons,
    ShimmerCategory,
    CardFive,
    CardTen,
  },
  async asyncData({ error, store, route, params }) {
    try {
      store.commit("seo/SET_SEO", {
        title: `Berita ${route.query.q.toLowerCase()} terkini dan terbaru hari ini`,
        desc: `Kumpulan berita dan informasi paling banyak dicari tentang ${route.query.q.toLowerCase()} terkini dan terbaru hari ini`,
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  data() {
    return {
      sort: "DESC",
      filter: "Semua",
      page: 1,
      limitSearch: 12,
      limit: 40,
      loadingSearch: true,
      arrow: {
        untukmu: {
          prev: true,
          next: false,
        },
      },
      swiperOptions: {
        categories: {
          // freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          mousewheel: {
            releaseOnEdges: true,
          },
          breakpoints: {
            1023: {
              slidesPerView: "auto",
              spaceBetween: 10,
            },
            320: {
              slidesPerView: "auto",
              spaceBetween: 10,
            },
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      interest: (state) => {
        return state.search.interest;
      },
      result: (state) => {
        return state.search.result;
      },
      pagination: (state) => {
        return state.search.pagination;
      },
      topics: (state) => {
        return state.search.topics;
      },
    }),
  },
  watch: {
    "$route.query.q": {
      handler: function (search) {
        this.initData();
      },
      deep: true,
      immediate: true,
    },
    "$route.query.page": {
      handler: function (page) {
        if (page) {
          let page_ = parseInt(page);
          this.page = page_;
        } else {
          this.page = 1;
        }
        this.initData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async sorting(type) {
      this.sort = type;
      await this.$store.dispatch("search/getResult", {
        search: this.$route.query.q,
        limit: this.limitSearch,
        sort: type,
        page: this.page ? this.page : 1,
      });
    },
    async initData() {
      await this.$store.dispatch(
        "search/getSponsorTopics",
        this.$route.query.q
      );
      await this.$store
        .dispatch("search/getResult", {
          search: this.$route.query.q,
          limit: this.limitSearch,
          sort: this.sort,
          page: this.page ? this.page : 1,
        })
        .then((res) => {
          this.loadingSearch = false;
        });
      await this.$store.dispatch("search/getPopuler", {
        limit: this.limit ? this.limit : 10,
        page: 1,
      });
    },
    clickCallback(page) {
      this.page = page;
      this.$router.replace({
        name: "search",
        query: {
          q: this.$route.query.q,
          page: page,
        },
      });

      this.$store.dispatch("search/getResult", {
        search: this.$route.query.q,
        limit: this.limitSearch,
        sort: this.sort,
        page: page,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.dot-spin-wrapper {
  height: 40px;
  margin-top: 30px;
}
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}
.active {
  background-color: #4a25aa !important;
  color: #fafafa !important;
}

.search-page {
  padding: 32px 0 48px;
  @media only screen and (max-width: 1024px) {
    margin-top: 92px;
  }

  .container {
    .search-title {
      margin-bottom: 24px;
      text-align: center;

      .title {
        font-size: 32px;
        font-weight: 700;
        color: #4a25aa;
        margin-bottom: 16px;
        @media only screen and (max-width: 1024px) {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }

      .desc {
        font-size: 14px;
        font-weight: 400;
        color: #757575;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }

    .search-category {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin: 40px 0;
      }

      .swiper-container {
        padding: 10px 0;
        @media only screen and (max-width: 1024px) {
          padding: 20px;
          margin: -20px;
        }

        .swiper-wrapper {
          height: fit-content !important;

          .swiper-slide {
            &.category {
              color: #616161;
              background: #fafafa;
              width: fit-content !important;
              border-radius: 50px;
              white-space: nowrap;
              font-size: 16px;
              padding: 10px 30px;
              border: 1px solid #e0e0e0;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
                padding: 5px 15px;
                margin: 0 5px 5px 0;
              }

              &.active {
                background: #4a25aa;
                color: #ffe900 !important;
              }

              &:hover {
                @media only screen and (min-width: 1024px) {
                  background: #8166c8;
                  color: #fafafa !important;
                }
              }

              &:active {
                @media only screen and (min-width: 1024px) {
                  background: #6242b4;
                  color: #e0e0e0 !important;
                }
              }
            }
          }
        }
      }
    }

    .search-result {
      margin-bottom: 48px;

      .not-found {
        text-align: center;

        svg {
          width: 150px;
          height: auto;
          @media only screen and (max-width: 1024px) {
            width: 90px;
            height: auto;
          }
        }
      }

      .filter {
        margin-bottom: 16px;
        cursor: pointer;
        // .arrow {
        //   @media only screen and (max-width: 1024px) {
        //     transform: rotate(180deg);
        //   }
        // }
        .dropdown-toggle {
          display: flex;
          align-items: center;

          &::after {
            display: none;
          }

          .text {
            margin: 0 20px 0 0;
            font-size: 16px;
            font-weight: bold;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
            }
          }

          svg {
            width: 10px;
            height: 10px;
          }
        }

        .dropdown-menu {
          padding: 0;
          border-radius: 10px;
          overflow: hidden;

          li {
            div {
              font-size: 16px;
              font-weight: 400;
              padding: 12px 15px;
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
              }

              &:hover {
                background: #4a25aa;
                color: white !important;
              }
            }
          }
        }
      }

      .search-list-card {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 24px;
        @media only screen and (max-width: 1024px) {
          display: flex;
          flex-wrap: wrap;
        }

        .search-card {
          width: 100%;
          min-width: 250px;
        }

        &.removeRow {
          grid-template-rows: repeat(1, 1fr);
        }
      }

      .pagination-cards {
        padding: 24px 0;

        .pagination {
          justify-content: center;
          margin: 0;
          align-items: center;
          @media only screen and (max-width: 1024px) {
            justify-content: center;
          }

          ::v-deep .arrow {
            display: none;
          }

          ::v-deep .page-item {
            a {
              font-size: 12px;
              font-weight: 400;
              color: #757575;
              background: #e0e0e0;
              width: 25px;
              height: 25px;
              margin: 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            &.active {
              a {
                background: #4a25aa;
                color: #9e9e9e !important;
              }
            }

            &:hover {
              a {
                background: #4a25aa;
                color: #9e9e9e !important;
              }
            }
          }
        }
      }
    }

    .search-topic-reco {
      margin-bottom: 48px;

      .topic-title {
        font-size: 32px;
        font-weight: 700;
        color: #4a25aa;
        text-align: center;
        margin-bottom: 32px;
        @media only screen and (max-width: 1024px) {
          font-size: 16px;
          margin-bottom: 24px;
        }
      }

      .topic-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 32px;
        gap: 16px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 24px;
          gap: 8px;
        }

        .topic {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #616161;
          background: #fafafa;
          padding: 8px 20px;
          border: 1px solid lightgray;
          border-radius: 50rem;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            padding: 6px 15px;
          }

          &.Link-exact-active {
            background: #4a25aa;
            color: #ffe900 !important;
          }

          &:hover {
            @media only screen and (min-width: 1024px) {
              background: #8166c8;
              color: #fafafa !important;
            }
          }

          &:active {
            @media only screen and (min-width: 1024px) {
              background: #6242b4;
              color: #e0e0e0 !important;
            }
          }

          p {
            margin: 0 0 0 10px;
          }

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .search-populer {
      .populer-title {
        font-size: 32px;
        font-weight: 700;
        color: #4a25aa;
        text-align: center;
        margin-bottom: 32px;
        @media only screen and (max-width: 1024px) {
          font-size: 16px;
          margin-bottom: 24px;
        }
      }

      .pagination-cards {
        padding: 24px 0;

        .pagination {
          justify-content: center;
          margin: 0;
          align-items: center;
          @media only screen and (max-width: 1024px) {
            justify-content: center;
          }

          ::v-deep .arrow {
            display: none;
          }

          ::v-deep .page-item {
            a {
              font-size: 12px;
              font-weight: 400;
              color: #757575;
              background: #e0e0e0;
              width: 25px;
              height: 25px;
              margin: 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            &.active {
              a {
                background: #4a25aa;
                color: #9e9e9e !important;
              }
            }

            &:hover {
              a {
                background: #4a25aa;
                color: #9e9e9e !important;
              }
            }
          }
        }
      }

      .populer-card-list {
        a {
          .card-populer {
            border-bottom: 1px solid lightgray;
          }

          &:last-child {
            .card-populer {
              border: none;
            }
          }
        }
      }
    }
  }
}
</style>
