var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-page"},[_c('div',{staticClass:"container"},[_c('section',{staticClass:"search-title"},[_c('h1',{staticClass:"title"},[_vm._v("HASIL PENCARIAN “"+_vm._s(_vm.$route.query.q)+"”")]),_vm._v(" "),_c('p',{staticClass:"desc"},[_vm._v("\n        "+_vm._s(_vm.result.items.length > 0
            ? "Hasil Pencarian " +
              _vm.result.pagination.total +
              " Artikel Berkaitan."
            : "Kami tidak dapat menemukan berita dan informasi berdasarkan kata kunci pencarian anda.")+"\n      ")])]),_vm._v(" "),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.result.items.length > 0),expression:"result.items.length > 0"}],staticClass:"search-category"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperCategories",value:(_vm.swiperOptions.categories),expression:"swiperOptions.categories",arg:"mySwiperCategories"}],staticClass:"py-2"},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.result.loading)?_c('ShimmerCategory'):_vm._e(),_vm._v(" "),_vm._l((_vm.result.channels),function(channel){return _c('div',{key:channel.id,staticClass:"swiper-slide category",class:channel === _vm.filter ? 'active' : '',attrs:{"role":"button"},on:{"click":function($event){_vm.filter = channel}}},[_vm._v("\n            "+_vm._s(channel)+"\n          ")])})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})])]),_vm._v(" "),_c('section',{staticClass:"search-result"}),_vm._v(" "),_c('section',{staticClass:"search-result"},[(_vm.loadingSearch)?_c('div',{staticClass:"dot-spin-wrapper"},[_c('div',{staticClass:"dot-spin m-auto"})]):_vm._e(),_vm._v(" "),(_vm.result.items.length < 1 && !_vm.loadingSearch)?_c('div',{staticClass:"not-found"},[_c('Icons',{attrs:{"name":"search-not-found"}})],1):_vm._e(),_vm._v(" "),(_vm.result.items.length > 0 && !_vm.loadingSearch)?_c('div',{staticClass:"search-list-card",class:{ removeRow: _vm.result.items.length <= 4 }},[_vm._l((4),function(item,index){return _c('ShimmerCardHomeTwo',{directives:[{name:"show",rawName:"v-show",value:(_vm.result.loading),expression:"result.loading"}],key:index + 'x'})}),_vm._v(" "),_vm._l((this.filter === 'Semua'
            ? _vm.result.items
            : _vm.result.items.filter(
                (article) => article.channel.title === this.filter
              )),function(item){return _c('CardTen',{directives:[{name:"show",rawName:"v-show",value:(!_vm.result.loading),expression:"!result.loading"}],key:item.id,staticClass:"search-card",attrs:{"to":item.isText
              ? {
                  name: 'read-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                }
              : {
                  name: 'video-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                },"image":item.thumbnail && item.thumbnail.medium
              ? item.thumbnail.medium
              : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '00:00',"channel":item.channel ? item.channel.title : '',"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial}})})],2):_vm._e(),_vm._v(" "),(
          _vm.result.items.length > 0 &&
          _vm.result.pagination.pages > 1 &&
          !_vm.loadingSearch
        )?_c('div',{staticClass:"pagination-cards"},[(
            _vm.filter === 'Semua' &&
            _vm.result.pagination &&
            _vm.result.pagination.pages &&
            _vm.result.pagination.pages > 1
          )?_c('paginate',{attrs:{"page-count":_vm.result.pagination.pages,"page-range":3,"margin-pages":1,"prev-class":"arrow","next-class":"arrow","click-handler":_vm.clickCallback,"container-class":'pagination',"page-class":'page-item'},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('template',{slot:"prevContent"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#757575"}})],1),_vm._v(" "),_c('template',{slot:"nextContent"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#757575"}})],1)],2):_vm._e()],1):_vm._e()]),_vm._v(" "),(
        _vm.$store.state.search.result.topics.length > 0 ||
        _vm.$store.state.search.topics.items.length > 0
      )?_c('section',{staticClass:"search-topic-reco"},[_c('h2',{staticClass:"topic-title"},[_vm._v("REKOMENDASI TOPIK")]),_vm._v(" "),_c('div',{staticClass:"topic-list"},[(
            _vm.$store.state.search.topics.loading &&
            _vm.$store.state.search.result.loading
          )?_c('ShimmerCategory'):_vm._e(),_vm._v(" "),_vm._l((_vm.$store.state.search.topics.items),function(topic){return _c('Link',{key:topic.id,staticClass:"topic",class:_vm.$route.query.q === topic.topic ? 'Link-exact-active' : '',attrs:{"to":`/search?q=${topic.topic}`}},[(topic.isSponsor)?_c('Icons',{attrs:{"name":"ads"}}):_vm._e(),_vm._v(" "),_c('p',[_vm._v("#"+_vm._s(topic.topic))])],1)})],2)]):_vm._e(),_vm._v(" "),_c('section',{staticClass:"search-populer"},[_c('h2',{staticClass:"populer-title"},[_vm._v("PENCARIAN POPULER")]),_vm._v(" "),_c('div',{staticClass:"populer-card-list"},[_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.search.popular.loading),expression:"$store.state.search.popular.loading"}],key:'shimmer-x-' + index})}),_vm._v(" "),_vm._l((_vm.$store.state.search.popular.items),function(item){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.search.popular.loading),expression:"!$store.state.search.popular.loading"}],key:item.id,attrs:{"to":item.isText
              ? {
                  name: 'read-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                }
              : {
                  name: 'video-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                },"image":item.thumbnail && item.thumbnail.large
              ? item.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '00:00',"channel":item.channel ? item.channel.title : '',"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isAdvertorial":item.isAdvertorial,"isText":item.isText}})})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }